<template>
  <div class="course">
    <li class="title">选手注册</li>
    <van-cell-group inset style="padding-top: 10px">
      <van-form @submit="onSubmit" inset>
        <van-field required v-model="name" name="name" label="姓名" maxlength="20" placeholder="请输入您的姓名"
          :rules="[{ required: true, message: '请输入您的姓名' }]" />
        <van-field required v-model="phone" name="phone" label="联系方式" type="tel" maxlength="11" placeholder="请输入您的联系方式"
          :rules="[{ required: true, validator, message: '请输入正确的手机号' }]"></van-field>

        <van-field required v-model="code" name="code" label="验证码" type="" maxlength="4" placeholder="验证码"
          :rules="[{ required: true, message: '请输入验证码' }]">
          <template #button>
            <van-button size="small" class="send" type="primary" native-type='button' :disabled="disabled"
              @click.stop='send'>
              {{ message }}
            </van-button>
          </template>
        </van-field>


        <van-field required name="sex" label="性别" :rules="[{ required: true, message: '请选择您的性别' }]">
          <template #input>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="男">先生</van-radio>
              <van-radio name="女">女士</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field required name="entry" label="项目名称" :rules="[{ required: true, message: '至少选择一项项目名称' }]">
          <template #input>
            <van-checkbox-group v-model="result" direction="horizontal">
              <van-checkbox v-for="i in project" :key="i.id" :name="i.n_title" shape="square" class="mb20">
                {{ i.n_title }}
              </van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit">
            提交
          </van-button>
          <!-- <van-button round block type="info" @click="show = true">提交</van-button> -->
        </div>

        <div class="tips">
          <div class="t"><span>*</span>如何获得深圳市电子竞技选手证？</div>
          <div class="a">
            <p><span>1、注册后备选手证书：</span>通过在深圳市电子竞技行业协会的官方公众号上注册，您可以获得“后备选手”证书。</p>
            <p><span>2、参与协会主办赛事：</span>参与由深圳市电子竞技行业协会主办或承办的会员单位级、区级、市级、全国级赛事，并在其中取得优异成绩，将有资格获得“正式选手”证书。</p>
            <p><span>3、参与其他组织或场馆赛事：</span>参与深圳市电子竞技行业协会及深圳市电子竞技行业协会会员单位、会员场馆组织的电子竞技赛事，同样可以通过取得名次获得“正式选手”证书。</p>
            <p><span>4、通过行业组织、电竞企业单位推荐：</span>如有行业组织或电竞企业单位的推荐，也可以获得“正式选手”证书。</p>
            <p><span>5、一证一项目：</span>每个证书只能对应一款游戏。申请证书时需要明确自己主要的电竞项目，以确保申请与您的实际技能和经验相符。</p>
            <p>
              <span>6、关注赛事信息公布：</span>关注深圳市电子竞技行业协会公众号，以及相关社群通知，以获取最新的赛事信息和注册渠道；公众号会定期发布赛事的报名信息和要求，您可以按照要求进行报名参赛，并按照规定的流程进行申请证书。
            </p>
            <p>
              <span>7、实体证书申请：</span>如需实体证书，可通过深圳市电子竞技行业协会公众号进行申请。在申请时需要提供相关的证明材料和个人资料，并支付相应的工本费和邮寄费用。实体证书将在审核通过后寄送到您提供的地址。
            </p>
          </div>
        </div>
      </van-form>
    </van-cell-group>

    <van-popup v-model="show" class="ma" closeable>
      <div class="ma1">
        <van-image width="160" height="160" fit="cover" :src="tip.code" />
        <li>保存并扫描上方二维码</li>
        <li>
          关注<span>"{{ tip.name }}"</span>官方公众号
        </li>
        <li>关注公众号即可<span>申请</span>/<span>查询</span>选手证书</li>
      </div>
    </van-popup>

    <div class="copy">{{ tip.name }} 版权所有</div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      result: [],
      name: "",
      phone: "",
      sex: '',
      code:'',

      show: false,

      tip: {},
      project: [],

      message: '发送验证码',
      disabled: false,
      countdown: 60,
      timer: null,

    };
  },
  components: {
    [Toast.name]: Toast, // 文字提示
  },
  created() {
    this.post({});
    // this.show = true;
  },
  mounted() {
    const savedCount = localStorage.getItem('countdownState');
    console.log(savedCount);
    clearInterval(this.timer);
    if (savedCount) {
      this.countdown = savedCount;
      this.startCountdown();
    }
  },
  computed: {},
  methods: {
    // 启动倒计时  
    startCountdown() {
      this.timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(this.timer); // 倒计时结束，清除定时器  
          this.message = '再次发送';
          this.countdown = 60;
          this.disabled = false;
          localStorage.removeItem('countdownState');
        } else {
          this.message = `${this.countdown}s`
          this.disabled = true;
          localStorage.setItem('countdownState', this.countdown);
        }
      }, 1000); // 每秒更新一次倒计时
    },
    // 验证码
    send() {
      let id = this.$localStorage.get('openid');
      let phone = this.phone;
      if (this.validator(phone)) {
        this.$request(4, { id, phone })
          .then((res) => {
            // this.startCountdown();
          })
          .catch((message) => {
            Toast(message);
          })
      } else if (!this.phone) {
        Toast('请输入手机号！');
      }

    },

    // 提交
    onSubmit(values) {
      values.entry = values.entry.toString();
      values.sex = values.sex.toString();
      values.openid = this.$localStorage.get('openid');

      this.$request(2, values)
        .then((res) => {
          this.$router.push({ path: '/examine' });
        })
        .catch((message) => {
          Toast(message);
        })
    },

    // 手机号正则
    validator(val) {
      return /^1[3-9]\d{9}$/.test(val);
    },

    // 加载配置
    post(e) {
      this.$request(1, e)
        .then((res) => {
          this.tip = JSON.parse(res.tip);
          this.project = res.project;
          this.show = true;
        })
        .catch((message) => {
          console.log(message);
          Toast(message);
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.mb20 {
  margin-bottom: 20px;
}

.ma {
  border-radius: 10px;
  padding: 30px;
  width: 80%;

  .ma1 {
    padding-top: 30px;
    text-align: center;

    li {
      font-size: 26px;
      line-height: 50px;
      color: #888;

      span {
        color: red;
      }
    }
  }
}

.course {
  min-height: 100vh;
  background-color: #f7f8fa;

  .title {
    margin: 0;
    padding: 40px 32px 32px;
    color: rgba(69, 90, 100, 0.8);
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
  }

  .tips {
    font-size: 30px;

    .t {
      padding: 30px 32px 0;
      // color: rgba(69, 90, 100, 1);
      font-weight: normal;
      font-size: 28px;
      line-height: 32px;

      span {
        display: inline-block;
        margin-right: 5px;
        color: red;
      }
    }

    .a {
      padding: 30px;
      text-indent: 1em;
      font-size: 28px;
      // color: rgba(69, 90, 100, 1);
      line-height: 50px;

      span {
        font-weight: 600;
        color: #000;
      }

      p {
        color: rgba(69, 90, 100, 1);
      }
    }
  }

  .copy {
    text-align: center;
    width: 100%;
    padding: 30px 0 60px;
    font-size: 24px;
    color: #999;
  }

  .send {
    width: 164px;
  }
}
</style>

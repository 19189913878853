import CryptoJS from "crypto-js";

/**
 * AES加密
 * @param {Object} data 待加密的数据
 * @param {Object} key 密钥
 */
let encrypt = function (str, keyStr, ivStr) {
  const data = JSON.stringify(str);
  const key = CryptoJS.enc.Utf8.parse(keyStr); // 密钥key   后台提供 - cbc模式 32位字符
  const iv = CryptoJS.enc.Utf8.parse(ivStr); // iv   后台提供 - cbc模式 16位字符

  const encryptedData = CryptoJS.AES.encrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC, // 使用 CBC 模式
    padding: CryptoJS.pad.Pkcs7, // 使用 PKCS7 填充
  }).toString(); // 执行加密操作

  return encryptedData;
};




export { encrypt };

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
Vue.config.productionTip = false;

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

import "amfe-flexible/index.js";
import "./assets/css/index.css";

import LocalStoragePlugin from "./utils/LocalStoragePlugin"; // 导入插件
import { encrypt } from "./utils/crypto-js.js";

Vue.use(LocalStoragePlugin); // 注册插件
Vue.prototype.$encrypt = encrypt;

import { Posting } from "./utils/api";
Vue.prototype.$request = Posting;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="course">
    <li class="title">基本信息</li>
    <van-cell-group inset style="padding: 2px 0">
      <van-cell-group>
        <van-cell title="姓名：" value-class="vlo" :value="info.name" />
        <van-cell title="联系方式：" value-class="vlo" :value="tel(info.phone) || '********'" />
        <van-cell title="性别：" value-class="vlo" :value="info.sex || '--'" />
        <van-cell title="类型：" value-class="vlo" :value="info.type || '后备选手'" />
        <!-- <van-cell title="参与项目名称：" value-class="vlo" :value="info.entry" /> -->
        <van-cell title="获得证书数量：" value-class="vlo" :value="info.count || '暂未获得'" />
        <van-cell title="证书申请状态：" value-class="vlo" v-if="crede.length > 0" :value="entry || '查看申请的证书'"
          @click="showPicker = true" />
      </van-cell-group>
    </van-cell-group>


    <van-cell-group inset style="padding: 15px">
      <van-button type="primary" block size="normal" @click="go">获取电子证书</van-button>
    </van-cell-group>

    <div class="tips">
      <div class="t"><span>*</span>证书介绍</div>
      <div class="a">
        <p>
          深圳市电子竞技选手证是由深圳市电子竞技行业协会对深圳地区参加全国各项赛事的电竞选手经过审核该选手符合入库标准。通过行业组织、电竞企业单位推荐，选手自愿加入深圳市电子竞技行业协会电子竞技选手库后所颁发的证书，获得该证书的电竞选手需要具备一定的实力和技能水平，并且需要遵守相关规定和要求。具体的申请条件和要求可能会因实际情况而有所调整。如有疑问，建议关注深圳市电子竞技行业协会的微信公众号直接获取最准确的信息。
        </p>
      </div>
    </div>

    <div class="copy">{{ tip.name }} 版权所有</div>

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>

  </div>
</template>

<script>
import { Dialog, ImagePreview, Toast } from 'vant';
export default {
  data() {
    return {
      info: {},
      tip: {},

      entry: '',
      showPicker: false,
      columns: [],

      show: false,

      crede: [],
    };
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Toast.name]: Toast, // 文字提示

  },
  created() {
    this.post();
    this.post2();
  },
  methods: {
    go() {
      let arr = this.crede.filter((v) => v.state == 0);
      if (arr.length > 0) {
        Toast('您还有未审核完的证书，请耐心等候！');
      } else {
        this.$router.push({ path: '/curriculum' });
      }
    },

    post() {
      this.$request(0, { openid: this.$localStorage.get('openid') })
        .then((res) => {
          let { info, crede } = res;
          this.$localStorage.set('userInfo', info);
          this.info = info;
          this.crede = crede;
          let arr = []
          crede.forEach((i, v) => {
            arr.push(`${i['entry']}[${i['state'] == '0' ? '审核中' : i['state'] == '1' ? '已认证' : '未通过'}]`)
          })
          this.columns = arr;

        })
        .catch((message) => {
          console.log('res', message);
        })
    },
    post2() {
      this.$request(1, {})
        .then((res) => {
          this.tip = JSON.parse(res.tip);
        })
        .catch((message) => {
          console.log(message);
          // Toast(message);
        })
    },

    // 选择项目
    onConfirm(value, index) {
      let _this = this;
      let info = this.crede[index];
      if (info.state == 0) {
        Toast(`您的【${info.entry}】证书正在审核中，请耐心等候。`);
      } else if (info.state == 1) {
        ImagePreview({
          images: [info.adds],
          closeable: true,
        });
      } else if (info.state == 2) {
        Dialog.confirm({
          title: `【${info.entry}】证书未通过`,
          message: `您本次申请的【${info.entry}】证书未通过，具体原因是：【${info.reason}】。`,
          confirmButtonText: '确定',
          confirmButtonColor: '#666',
          cancelButtonText: '取消',
          cancelButtonColor: '#666',
        })
          .then(() => { })
          .catch(() => { });
      }
      this.showPicker = false;
    },

    tel(v) {
      return v ? v.toString().replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2") : '';
    },


    formattedDateTime(data) {
      let currentDate = Date.parse(data)
      const year = currentDate.getFullYear();
      const month = this.zero(currentDate.getMonth() + 1);
      const day = this.zero(currentDate.getDate());
      const hours = this.zero(currentDate.getHours());
      const minutes = this.zero(currentDate.getMinutes());
      const seconds = this.zero(currentDate.getSeconds());
      return `${year}-${month}-${day}- ${hours}:${minutes}:${seconds}`;
    },

    zero(n) {
      return n > 9 ? n : '0' + n
    },

    state(n) {
      let arr = ['暂未激活电子证书', '审核中', '已激活']
      return arr[n]
    },

    changeOverlay() {

      console.log('???');
    },
  },
};
</script>

<style lang="scss" scoped>
.vlo {
  color: #000;
}

.mb20 {
  margin-bottom: 20px;
}

.ma {
  border-radius: 10px;
  padding: 30px;
  width: 80%;

  .ma1 {
    padding-top: 30px;
    text-align: center;

    li {
      font-size: 26px;
      line-height: 50px;
      color: #888;

      span {
        color: red;
      }
    }
  }
}

.course {
  min-height: 100vh;
  background-color: #f7f8fa;

  .title {
    margin: 0;
    padding: 40px 32px 32px;
    color: rgba(69, 90, 100, 0.8);
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
  }

  .tips {
    margin-bottom: 20px;

    .t {
      padding: 30px 32px;
      color: #000;
      font-weight: normal;
      font-size: 28px;
      line-height: 32px;

      span {
        display: inline-block;
        margin-right: 5px;
        color: red;
      }
    }

    .a {
      padding: 0 30px;
      text-indent: 2em;
      font-size: 28px;
      color: rgba(69, 90, 100, 1);
      line-height: 50px;
    }
  }

  .copy {
    width: 100%;
    font-size: 24px;
    color: #999;
    padding: 30px 0;
    text-align: center;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>
